export const AdminAuthorityType = {
  USER_CREATE: "USER_CREATE",
  USER_EDIT: "USER_EDIT",
  USER_DELETE: "USER_DELETE",
  USER_EXCEL: "USER_EXCEL",
  SALES_EMAIL_CREATE: "SALES_EMAIL_CREATE",
  SALES_EMAIL_EDIT: "SALES_EMAIL_EDIT",
  SALES_EMAIL_DELETE: "SALES_EMAIL_DELETE",
  DEVELOPER: "DEVELOPER",
  EXECUTIVE: "EXECUTIVE",
};

export type AdminAuthorityTypeString =
  (typeof AdminAuthorityType)[keyof typeof AdminAuthorityType];

export const UserAuthorityType = {
  CUSTOMER_CARD_CREATE: "CUSTOMER_CARD_CREATE",
  CUSTOMER_CARD_EDIT: "CUSTOMER_CARD_EDIT",
  CUSTOMER_CARD_DELETE: "CUSTOMER_CARD_DELETE",
  SALES_REPORT_CREATE: "SALES_REPORT_CREATE",
  SALES_REPORT_EDIT: "SALES_REPORT_EDIT",
  SALES_REPORT_DELETE: "SALES_REPORT_DELETE",
};

export type UserAuthorityTypeString =
  (typeof UserAuthorityType)[keyof typeof UserAuthorityType];
