import Vue from "vue";
import vuetify from "@/plugins/vuetify";
import App from "@/App.vue";
import router from "@/router";
import "@/plugins/vee-validate";
import "@/assets/scss/theme.scss";
import { SENTRY_DSN, ENVIRONMENT } from "@/constants/envs";
import * as Sentry from "@sentry/vue";
import { PiniaVuePlugin } from "pinia";
import pinia from "@/stores";

Vue.use(PiniaVuePlugin);

if (SENTRY_DSN && ENVIRONMENT !== "local") {
  Sentry.init({
    Vue: Vue,
    dsn: SENTRY_DSN,
    environment: ENVIRONMENT,
    integrations: [new Sentry.Replay()],
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 1.0,
  });
}

new Vue({
  pinia,
  router,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
