import Vue from "vue";
import Router from "vue-router";
import type { Route } from "vue-router";
import type { NavigationGuardNext, RouteConfig } from "vue-router/types/router";
import store from "@/stores";
import { goSignInPage } from "@/utils/commands";
import { useAdminStore } from "@/stores/admin";
import { storeToRefs } from "pinia";
import { useAlertStore } from "@/stores/alert";

Vue.use(Router);

const requireAdmin =
  () => async (_to: Route, _from: Route, next: NavigationGuardNext) => {
    const { hasAdminAuthority } = useAdminStore(store);
    const { toastWarning } = useAlertStore();

    if (!hasAdminAuthority) {
      toastWarning("권한이 없습니다. 관리자에게 문의해주세요.");
      if (_from.fullPath === "/") {
        next("/");
      }
      return;
    }
    return requireAuth()(_to, _from, next);
  };
const requireAuth =
  () => async (_to: Route, _from: Route, next: NavigationGuardNext) => {
    const { loggedIn } = storeToRefs(useAdminStore(store));
    if (loggedIn) {
      return next();
    }
    return goSignInPage();
  };

const routes = (): RouteConfig[] => {
  const sales: RouteConfig[] = [
    {
      path: "/sales/email-management",
      beforeEnter: requireAuth(),
      component: () => import("@/views/sales/email/EmailManagementPage.vue"),
    },
  ];
  const management: RouteConfig[] = [
    {
      path: "/management/admin",
      beforeEnter: requireAdmin(),
      component: () => import("@/views/management/admin/AdminPage.vue"),
    },
    {
      path: "/management/user",
      beforeEnter: requireAuth(),
      component: () => import("@/views/management/user/UserPage.vue"),
    },
  ];
  const error: RouteConfig[] = [
    {
      path: "/error",
      component: () => import("@/views/error/UnexpectedPage.vue"),
      meta: {
        layout: "error",
      },
    },
    {
      path: "/error/404",
      component: () => import("@/views/error/NotFoundPage.vue"),
      meta: {
        layout: "error",
      },
    },
  ];

  return [
    {
      path: "/",
      beforeEnter: requireAuth(),
      component: () => import("@/views/HomePage.vue"),
    },
    {
      path: "/sign-in",
      component: () => import("@/views/sign/in/SignIn.vue"),
      meta: {
        layout: "auth",
      },
    },
    ...sales,
    ...management,
    ...error,
    {
      path: "*",
      redirect: "/error/404",
    },
  ];
};

export default new Router({
  mode: "history",
  base: import.meta.env.BASE_URL,
  scrollBehavior(_to: Route, _from: Route, savedPosition) {
    return savedPosition || { x: 0, y: 0 };
  },
  routes: routes(),
});
