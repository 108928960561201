import { defineStore } from "pinia";
import jwt_decode from "jwt-decode";
import { getNewToken, signOut } from "@/utils/commands";
import type { LoggedInAdmin } from "@/definitions/types";
import type { AdminAuthorityTypeString } from "@/definitions/authorities";
import { AdminAuthorityType } from "@/definitions/authorities";

export const useAdminStore = defineStore("admin", {
  state: (): LoggedInAdmin => {
    return {
      id: 0,
      loginId: "",
      name: "",
      accessToken: "",
      authoritiesJson: [],
    };
  },
  getters: {
    loggedIn: (state: LoggedInAdmin): boolean =>
      !!state.id &&
      !!state.accessToken &&
      !!window.localStorage.getItem("accessToken") &&
      !!window.localStorage.getItem("refreshToken"),
    admin: (state: LoggedInAdmin): LoggedInAdmin => {
      return {
        id: state.id,
        loginId: state.loginId,
        name: state.name,
        accessToken: state.accessToken,
        authoritiesJson: state.authoritiesJson,
      };
    },
    hasAdminDeveloperAuthority: (state: LoggedInAdmin): boolean => {
      return state.authoritiesJson.includes(AdminAuthorityType.DEVELOPER);
    },
    hasAdminExecutiveAuthority: (state: LoggedInAdmin): boolean => {
      return state.authoritiesJson.includes(AdminAuthorityType.EXECUTIVE);
    },
    hasAdminAuthority: (state: LoggedInAdmin): boolean => {
      return state.authoritiesJson.some((auth) =>
        [AdminAuthorityType.DEVELOPER, AdminAuthorityType.EXECUTIVE].includes(
          auth,
        ),
      );
    },
    hasUserCreateAuthority(state: LoggedInAdmin): boolean {
      return state.authoritiesJson.some((auth) =>
        [
          AdminAuthorityType.DEVELOPER,
          AdminAuthorityType.EXECUTIVE,
          AdminAuthorityType.USER_CREATE,
        ].includes(auth),
      );
    },
    hasUserEditAuthority: (state: LoggedInAdmin): boolean => {
      return state.authoritiesJson.some((auth) =>
        [
          AdminAuthorityType.DEVELOPER,
          AdminAuthorityType.EXECUTIVE,
          AdminAuthorityType.USER_EDIT,
        ].includes(auth),
      );
    },
    hasUserDeleteAuthority: (state: LoggedInAdmin): boolean => {
      return state.authoritiesJson.some((auth) =>
        [
          AdminAuthorityType.DEVELOPER,
          AdminAuthorityType.EXECUTIVE,
          AdminAuthorityType.USER_DELETE,
        ].includes(auth),
      );
    },
    hasUserExcelAuthority: (state: LoggedInAdmin): boolean => {
      return state.authoritiesJson.some((auth) =>
        [
          AdminAuthorityType.DEVELOPER,
          AdminAuthorityType.EXECUTIVE,
          AdminAuthorityType.USER_EXCEL,
        ].includes(auth),
      );
    },
    hasSalesEmailCreateAuthority: (state: LoggedInAdmin): boolean => {
      return state.authoritiesJson.some((auth) =>
        [
          AdminAuthorityType.DEVELOPER,
          AdminAuthorityType.EXECUTIVE,
          AdminAuthorityType.SALES_EMAIL_CREATE,
        ].includes(auth),
      );
    },
    hasSalesEmailEditAuthority: (state: LoggedInAdmin): boolean => {
      return state.authoritiesJson.some((auth) =>
        [
          AdminAuthorityType.DEVELOPER,
          AdminAuthorityType.EXECUTIVE,
          AdminAuthorityType.SALES_EMAIL_EDIT,
        ].includes(auth),
      );
    },
    hasSalesEmailDeleteAuthority: (state: LoggedInAdmin): boolean => {
      return state.authoritiesJson.some((auth) =>
        [
          AdminAuthorityType.DEVELOPER,
          AdminAuthorityType.EXECUTIVE,
          AdminAuthorityType.SALES_EMAIL_DELETE,
        ].includes(auth),
      );
    },
  },
  actions: {
    clearAdmin(): void {
      window.localStorage.removeItem("accessToken");
      window.localStorage.removeItem("refreshToken");
      this.$reset();
    },
    async reIssueAccessToken(): Promise<void> {
      const newToken = await getNewToken();
      if (newToken) {
        this.saveToken(newToken);
      }
    },
    saveToken(payload: { accessToken: string; refreshToken: string }): void {
      try {
        const jwt = jwt_decode<{
          exp: number;
          id: number;
          loginId: string;
          name: string;
          authoritiesJson: AdminAuthorityTypeString[];
        }>(payload.accessToken);
        window.localStorage.setItem("accessToken", payload.accessToken);
        window.localStorage.setItem("refreshToken", payload.refreshToken);
        this.id = jwt.id;
        this.loginId = jwt.loginId;
        this.name = jwt.name;
        this.authoritiesJson = jwt.authoritiesJson ?? [];
        this.accessToken = payload.accessToken;
      } catch (e: unknown) {
        signOut().then();
      }
    },
  },
  persist: true,
});
