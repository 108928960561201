import type { SelectItem } from "@/definitions/types";
import type {
  AdminAuthorityTypeString,
  UserAuthorityTypeString,
} from "@/definitions/authorities";
import {
  AdminAuthorityType,
  UserAuthorityType,
} from "@/definitions/authorities";

export const MenuType = {
  GROUP: "GROUP",
  PAGE: "PAGE",
  NEW_TAB: "NEW_TAB",
};
export type MenuTypesString = (typeof MenuType)[keyof typeof MenuType];
export const BOOLEAN_TYPE: SelectItem<boolean>[] = [
  { value: true, text: "예" },
  { value: false, text: "아니요" },
];
export const CheckboxStatus = {
  TRUE: "TRUE",
  FALSE: "FALSE",
  INTERMEDIATE: "INTERMEDIATE",
};

export const USER_AUTHORITY_TYPE: SelectItem<UserAuthorityTypeString>[] = [
  {
    value: UserAuthorityType.CUSTOMER_CARD_CREATE,
    text: "회원 카드 생성",
  },
  { value: UserAuthorityType.CUSTOMER_CARD_EDIT, text: "회원 카드 수정" },
  {
    value: UserAuthorityType.CUSTOMER_CARD_DELETE,
    text: "회원 카드 삭제",
  },
  { value: UserAuthorityType.SALES_REPORT_CREATE, text: "세일즈 리포트 생성" },
  { value: UserAuthorityType.SALES_REPORT_EDIT, text: "세일즈 리포트 수정" },
  {
    value: UserAuthorityType.SALES_REPORT_DELETE,
    text: "세일즈 리포트 삭제",
  },
];
export const ADMIN_AUTHORITY_TYPE: SelectItem<AdminAuthorityTypeString>[] = [
  { value: AdminAuthorityType.USER_CREATE, text: "회원 등록" },
  { value: AdminAuthorityType.USER_EDIT, text: "회원 수정" },
  { value: AdminAuthorityType.USER_DELETE, text: "회원 삭제" },
  { value: AdminAuthorityType.USER_EXCEL, text: "회원 엑셀" },
  {
    value: AdminAuthorityType.SALES_EMAIL_CREATE,
    text: "이메일 수신 목록 등록",
  },
  { value: AdminAuthorityType.SALES_EMAIL_EDIT, text: "이메일 수신 목록 수정" },
  {
    value: AdminAuthorityType.SALES_EMAIL_DELETE,
    text: "이메일 수신 목록 삭제",
  },
  { value: AdminAuthorityType.DEVELOPER, text: "개발자" },
  { value: AdminAuthorityType.EXECUTIVE, text: "관리자" },
];
