<template>
  <div class="d-flex flex-grow-1">
    <!-- Navigation -->
    <v-navigation-drawer
      v-model="drawer"
      app
      floating
      class="elevation-1"
      :right="$vuetify.rtl"
      light
    >
      <!-- Navigation menu info -->
      <template #prepend>
        <div class="pa-2">
          <div
            class="text-h6 font-weight-bold primary--text"
            v-text="PRODUCT_TITLE"
          />
          <div
            class="text-overline grey--text"
            style="text-transform: none"
            v-text="PRODUCT_VERSION"
          />
        </div>
      </template>

      <!-- Navigation menu -->
      <NavMenu v-if="drawerList.length > 0" :drawers="drawerList" />
    </v-navigation-drawer>

    <!-- Toolbar -->
    <v-app-bar app :flat="false" light>
      <v-card class="flex-grow-1 d-flex pa-0 ma-0" :flat="true">
        <div class="d-flex flex-grow-1 align-center">
          <div class="d-flex flex-grow-1 align-center">
            <v-app-bar-nav-icon @click.stop="drawer = !drawer" />

            <v-spacer class="d-none d-lg-block" />

            <v-spacer class="d-block d-sm-none" />

            <ToolbarAdmin />

            <h4 class="primary--text mt-1" v-text="name" />
          </div>
        </div>
      </v-card>
    </v-app-bar>

    <v-main>
      <v-container class="fill-height pa-4" :fluid="true">
        <v-layout>
          <slot />
          <input type="text" style="width: 0; height: 0" />
        </v-layout>
      </v-container>
    </v-main>
  </div>
</template>

<script setup lang="ts">
import NavMenu from "@/components/navigation/NavMenu.vue";
import { computed, ref } from "vue";
import { storeToRefs } from "pinia";
import { useAdminStore } from "@/stores/admin";
import type { Drawer } from "@/definitions/types";
import { MenuType } from "@/definitions/selections";
import ToolbarAdmin from "@/components/toolbar/ToolbarAdmin.vue";
import { PRODUCT_TITLE, PRODUCT_VERSION } from "@/constants/envs";

const { name } = storeToRefs(useAdminStore());
const {
  hasAdminAuthority,
  hasUserCreateAuthority,
  hasUserEditAuthority,
  hasUserDeleteAuthority,
  hasUserExcelAuthority,
  hasSalesEmailCreateAuthority,
  hasSalesEmailEditAuthority,
  hasSalesEmailDeleteAuthority,
} = useAdminStore();

const drawer = ref(true);

const drawerList = computed<Drawer[]>(() => {
  const result: Drawer[] = [];

  if (
    hasUserCreateAuthority ||
    hasUserEditAuthority ||
    hasUserDeleteAuthority ||
    hasUserExcelAuthority
  ) {
    result.push({
      id: 1,
      name: "회원",
      type: MenuType.GROUP,
      icon: "mdi-account",
      url: null,
      children: [
        {
          id: 11,
          name: "회원목록",
          type: MenuType.PAGE,
          icon: null,
          url: "/management/user",
          children: null,
        },
      ],
    });
  }

  if (
    hasSalesEmailCreateAuthority ||
    hasSalesEmailEditAuthority ||
    hasSalesEmailDeleteAuthority
  ) {
    result.push({
      id: 2,
      name: "세일즈 리포트",
      type: MenuType.GROUP,
      icon: "mdi-dots-horizontal-circle",
      url: null,
      children: [
        {
          id: 21,
          name: "이메일 수신 설정",
          type: MenuType.PAGE,
          icon: null,
          url: "/sales/email-management",
          children: null,
        },
      ],
    });
  }

  if (hasAdminAuthority) {
    result.push({
      id: 3,
      name: "관리자",
      type: MenuType.GROUP,
      icon: "mdi-account-supervisor-circle",
      url: null,
      children: [
        {
          id: 31,
          name: "관리자 목록",
          type: MenuType.PAGE,
          icon: null,
          url: "/management/admin",
          children: null,
        },
      ],
    });
  }

  return result;
});
</script>
